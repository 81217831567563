<template>
  <div v-if="loadingComplete">
    <div class="d-flex justify-content-between mb-10">
      <div class="form-group mb-0 col-xl-6 px-0">
        <input
          id="page_table_search"
          v-model="search"
          type="text"
          :placeholder="$t('GENERAL.INPUT.SEARCH')"
          class="form-control form-control-solid h-auto py-3 px-5 w-75"
          :disabled="loadingComplete == false"
        />
      </div>
    </div>
    <div class="table-responsive mb-0">
      <table
        id="pages_summary_table"
        class="table table-head-custom table-vertical center table-hover col-xl-7"
        style="table-layout:auto; overflow: auto"
      >
        <thead>
          <tr>
            <th class="px-2 py-3" style="width:45px"></th>
            <th class="pl-5 py-3" style="width:300px">
              {{ $t('TABLES.PAGES_SUMMARY.TITLE') }}
            </th>
            <th class="px-2 py-3" style="width:120px">
              {{ $t('TABLES.PAGES_SUMMARY.TYPE') }}
            </th>
            <th class="px-2 py-3" style="width:180px">
              {{ $t('TABLES.PAGES_SUMMARY.CREATED_AT') }}
            </th>
            <th class="px-2 py-3 text-right" style="width:200px">
              {{ $t('TABLES.PAGES_SUMMARY.ACTIONS') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="pages.length == 0 && loadingComplete">
            <td class="px-2 text-center" colspan="5">
              <span class="text-dark-65 font-weight-bold d-block py-3">
                {{ $t('TABLES.PAGES_SUMMARY_MESSAGES.NO_PAGES_TEXT') }}
                <router-link
                  tag="a"
                  class="text-primary"
                  :to="{ name: 'add_page' }"
                >
                  {{ $t('TABLES.PAGES_SUMMARY_MESSAGES.NO_PAGES_LINK') }}
                </router-link>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <draggable :list="pages" @end="onEnd" class="table-responsive table-hover" handle=".drag-page">
      <template v-for="(page, i) in pages">
        <PagesSummaryRow
          v-if="pageInSearch(page) || sectionInSearch(page.page_sections)"
          :key="i"
          :data="page"
          :search="search"
        >
        </PagesSummaryRow>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from "vuex";
import { UPDATE_PAGE_ORDER } from "@/core/services/store/page/page.module";

import PagesSummaryRow from "@/view/content/pages/PagesSummaryRow";

export default {
  name: "PageSummaryTable",

  props: {
    error: { type: Object, required: true },
    pageLoader: { type: Object, required: true },
    pages: { type: Array, required: true }
  },

  data() {
    return {
      search: "",
    }
  },

  components: {
    draggable,
    PagesSummaryRow
  },

  methods: {
    pageInSearch: function(page) {
      let title = page.title.toLowerCase();
      let type = page.page_type.name.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        type.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    sectionInSearch: function(sections) {
      let sectionTitles = "";
      sections.forEach(function (item) {
        sectionTitles = sectionTitles + " " + item.title;
      });

      if (
        sectionTitles.toLowerCase().includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    onEnd: function() {
      let pageOrder = [];
      this.pages.forEach(function (page) {
        pageOrder.push(page.id);
      });

      let payload = {
        projectId: this.projectInfo.id,
        data: {
          pages: pageOrder
        }
      };

      this.$store.dispatch(UPDATE_PAGE_ORDER, payload);
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>