<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div>
            <h6>{{ $t('NAVIGATION.PROJECT_MENU.PAGES') }}</h6>
            <p class="mb-10">{{ $t('PAGES.SUMMARY.DESCRIPTION') }}</p>              
          </div>
          <div>
            <router-link
              to="add"
              tag="a"
              class="btn btn-primary"
            >
              {{ $t('PAGES.SUMMARY.ADD') }}
            </router-link>
          </div>
        </div>

        <b-alert v-if="error.active" show variant="danger">
          {{ error.message }}
        </b-alert>

        <b-spinner
          v-if="loadingComplete == false && error.active == false"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>

        <PagesSummaryTable
          :pageLoader="pageLoader"
          :error="error"
          :pages="pageList"
        >
        </PagesSummaryTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_PAGE_LIST } from "@/core/services/store/page/page.module";

import PagesSummaryTable from "@/view/content/pages/PagesSummaryTable";

export default {
  name: "PageSummary",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    PagesSummaryTable
  },

  mounted() {
    this.fetchPages();
  },

  methods: {
    fetchPages: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          pageType: 'with',
          pageSections: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_PAGE_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the page list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "pageList"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
