<template>
  <table class="table center col-xl-7">
    <tbody>
      <tr
        v-on:mouseover="hovering = true"
        v-on:mouseleave="hovering = false"
        class="drag-page"
      >
        <td class="pl-1" style="width:45px">
          <a
            href="javascript:void(0)"
          >
            <i class="fas fa-grip-vertical text-dark-25 icon-lg mt-2 ml-2"></i>
          </a>
        </td>
        <td class="d-table-cell py-5 px-0" style="width:300px">
          <PublishedState
            :componentId="data.id"
            updateUri="pages"
            :published="data.published"
          >
          </PublishedState>
          <router-link
            tag="a"
            :to="{ name: 'edit_page', params: { page: data.id }}"
            class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
          >
            {{ data.title }}
          </router-link>
        </td>
        <td class="d-table-cell py-5 px-2" style="width:120px">
          <span
            class="d-block font-size-sm"
          >
            {{ data.page_type.name }}
          </span>
        </td>
        <td class="d-table-cell py-5 px-2" style="width:180px">
          <span
            class="d-block font-size-sm"
          >
            <GenericDatetimeDisplay
              :long="true"
              :date="data.created_at"
              :createdAt="true"
              :lastUpdated="false"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
        </td>
        <td class="pr-1 pt-3 pb-0 text-right d-table-cell" style="width:200px">
          <div v-if="hovering">
            <router-link
              v-if="data.page_type.sections_allowed"
              tag="a"
              :to="{ name: 'add_page_section', params: { page: data.id }}"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
              v-b-tooltip.hover.bottom
              :title="$t('TABLES.PAGES_SUMMARY_HEADERS.ADD_SECTION')"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Plus.svg" />
              </span>
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'edit_page', params: { page: data.id }}"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
              v-b-tooltip.hover.bottom
              :title="$t('TABLES.PAGES_SUMMARY_HEADERS.EDIT')"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Design/Edit.svg" />
              </span>
            </router-link>
            <a
              href="javascript:void(0)"
              class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
              v-b-tooltip.hover.bottom
              :title="$t('TABLES.PAGES_SUMMARY_HEADERS.DESTROY')"
              @click="deleteActivity"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="media/svg/icons/Home/Trash.svg" />
              </span>
            </a>
          </div>
        </td>
      </tr>
      <draggable :list="data.page_sections" @end="onEnd" :handle="'.drag-section-'+ data.id" style="display: contents">
        <template v-for="(section, s) in data.page_sections">
          <PagesSectionRow
            v-if="sectionInSearch(section)"
            :key="'section-' + data.id + '-' + s"
            :data="section"
            :class="'drag-section-'+ data.id"
          >
          </PagesSectionRow>
        </template>
      </draggable>
      <tr
        v-if="data.page_type.sections_allowed"
      >
        <td style="width:30px">
        </td>
        <td class="pl-5 py-5" colspan="4">
          <router-link
            tag="a"
            :to="{ name: 'add_page_section', params: { page: data.id }}"
            class="d-block font-size-sm"
          >
            {{ $t('TABLES.PAGES_SUMMARY.ADD_SECTION') }}
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import draggable from 'vuedraggable';
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

import { mapGetters } from "vuex";
import { DELETE_PAGE } from "@/core/services/store/page/page.module";
import { UPDATE_PAGE_SECTION_ORDER } from "@/core/services/store/page/page_section.module";

import PagesSectionRow from "@/view/content/pages/PagesSectionRow";
import PublishedState from "@/view/components/table-columns/general/PublishedState";

export default {
  name: "PagesSummaryRow",

  props: {
    data: { type: Object, required: true },
    search: { type: String, required: true }
  },

  data() {
    return {
      hovering: false
    }
  },

  components: {
    draggable,
    PagesSectionRow,
    PublishedState,
    GenericDatetimeDisplay
  },

  methods: {
    sectionInSearch: function(section) {
      let title = section.title.toLowerCase();

      if (
        title.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    deleteActivity: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.PAGE_DELETE_CHALLENGE.TITLE")}`,
        text: this.data.title + " " + `${this.$t("SWEETALERTS.PAGE_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.PAGE_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId: this.projectInfo.id,
            id: this.data.id
          };
          this.$store.dispatch(DELETE_PAGE, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.PAGE_DELETE_SUCCESS.TITLE")}`,
              text: this.data.title + ' ' + `${this.$t("SWEETALERTS.PAGE_DELETE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.PAGE_DELETE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    },
    onEnd: function() {
      let sectionOrder = [];
      this.data.page_sections.forEach(function (section) {
        sectionOrder.push(section.id);
      });

      let payload = {
        projectId: this.projectInfo.id,
        id: this.data.id,
        data: {
          sections: sectionOrder
        }
      };

      this.$store.dispatch(UPDATE_PAGE_SECTION_ORDER, payload);
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),

    calculatedPublishedClass: function() {
      if (this.data.published) {
        return "label-success"
      } else {
        return "label-danger"
      }
    },
    calculatedPublishedTitle: function() {
      if (this.data.published) {
        return `${this.$t("GENERAL.STATUS.PUBLISHED")}`;
      } else {
        return `${this.$t("GENERAL.STATUS.NOT_PUBLISHED")}`
      }
    }
  }
};
</script>
