<template>
  <a href="javascript:void(0)" @click="togglePublishedState">
    <span
      class="label label-dot mr-2"
      :class="labelState['class']"
      v-b-tooltip.hover.top
      :title="labelState['title']"
    >
    </span>
  </a>
</template>

<script>
import { TOGGLE_PUBLISHED_STATE } from "@/core/services/store/core/publishing.module";

export default {
  name: "PublishedState",

  data() {
    return {
      localPublished: null  
    }
  },

  props: {
    componentId: { type: Number, required: true },
    updateUri : { type: String, required: true },
    published: { type: Number, required: true }
  },

  methods: {
    togglePublishedState: function() {
      let payload = {
        id: this.componentId,
        uri: this.updateUri,
        published: (this.computedPublished ? 0 : 1)
      };

      this.$store.dispatch(TOGGLE_PUBLISHED_STATE, payload)
        .then(() => {
          this.localPublished = payload.published;
          this.$emit('changed',payload.published);
        });
    },
  },

  computed: {
    labelState: function() {
      let labelState = {};
      if (this.computedPublished) {
        labelState['class'] = "label-success";
        labelState['title'] = `${this.$t("GENERAL.STATUS.PUBLISHED")}`;
      } else {
        labelState['class'] = "label-danger";
        labelState['title'] = `${this.$t("GENERAL.STATUS.NOT_PUBLISHED")}`
      }
      return labelState;
    },
    computedPublished: function() {
      if (this.localPublished == null) {
        return this.published;
      } else {
        return this.localPublished
      }
    }
  }
}
</script>