<template>
  <tr
    v-on:mouseover="hovering = true"
    v-on:mouseleave="hovering = false"
  >
    <td class="pl-5">
      <a
        href="javascript:void(0)"
      >
        <i class="fas fa-grip-vertical text-dark-25 icon-lg mt-2 ml-2"></i>
      </a>
    </td>
    <td class="d-table-cell py-5 pl-5">
      <PublishedState
        :componentId="data.id"
        updateUri="page_sections"
        :published="data.published"
      >
      </PublishedState>
      <router-link
        tag="a"
        :to="{ name: 'edit_page_section', params: { page: data.page_id, section: data.id }}"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
      >
        {{ data.title }}
      </router-link>
    </td>
    <td class="d-table-cell py-5 pl-0">
      <span
        class="d-block font-size-sm"
      >
        
      </span>
    </td>
    <td class="d-table-cell py-5 px-2">
      <span
        class="d-block font-size-sm"
      >
        <GenericDatetimeDisplay
          :long="true"
          :date="this.data.created_at"
          :createdAt="true"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </span>
    </td>
    <td class="pr-1 pt-3 pb-0 text-right d-table-cell">
      <div v-if="hovering">
        <router-link
          tag="a"
          :to="{ name: 'edit_page_section', params: { page: data.page_id, section: data.id }}"
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
          v-b-tooltip.hover.bottom
          :title="$t('TABLES.PAGES_SUMMARY_HEADERS.EDIT')"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="media/svg/icons/Design/Edit.svg" />
          </span>
        </router-link>
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
          v-b-tooltip.hover.bottom
          :title="$t('TABLES.PAGES_SUMMARY_HEADERS.DESTROY')"
          @click="deleteSection"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <inline-svg src="media/svg/icons/Home/Trash.svg" />
          </span>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_PAGE_SECTION } from "@/core/services/store/page/page_section.module";
import { MUTATE_PAGE_SECTION } from "@/core/services/store/page/page.module";

import PublishedState from "@/view/components/table-columns/general/PublishedState";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "PagesSectionRow",

  props: {
    data: { type: Object, required: true }
  },

  data() {
    return {
      hovering: false
    }
  },

  components: {
    PublishedState,
    GenericDatetimeDisplay
  },

  methods: {
    deleteSection: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_CHALLENGE.TITLE")}`,
        text: this.data.title + " " + `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            pageId: this.data.page_id,
            id: this.data.id
          };
          this.$store.dispatch(DELETE_PAGE_SECTION, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_SUCCESS.TITLE")}`,
              text: this.data.title + ' ' + `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
            let secondPayload = {
              projectId: this.projectInfo.id,
              id: this.data.page_id,
              params: {
                paginate: false,
                pageType: 'with',
                pageSections: 'with'
              }
            }
            this.$store.dispatch(MUTATE_PAGE_SECTION, secondPayload);
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.PAGE_SECTION_DELETE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
    
    calculatedPublishedClass: function() {
      if (this.data.published) {
        return "label-success"
      } else {
        return "label-danger"
      }
    },
    calculatedPublishedTitle: function() {
      if (this.data.published) {
        return `${this.$t("GENERAL.STATUS.PUBLISHED")}`;
      } else {
        return `${this.$t("GENERAL.STATUS.NOT_PUBLISHED")}`
      }
    }
  }
};
</script>
